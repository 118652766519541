import { useState } from 'react';

import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { useParams } from 'react-router-dom';

import Alert from '@zenbusiness/zui/Alert';
import Breadcrumbs from '@zenbusiness/zui/Breadcrumbs';
import Button from '@zenbusiness/zui/Button';
import Center from '@zenbusiness/zui/Center';
import FormControl from '@zenbusiness/zui/FormControl';
import InputLabel from '@zenbusiness/zui/InputLabel';
import Link from '@zenbusiness/zui/Link';
import LoadingSpinner from '@zenbusiness/zui/LoadingSpinner';
import MenuItem from '@zenbusiness/zui/MenuItem';
import PageContainer from '@zenbusiness/zui/PageContainer';
import PageHeader from '@zenbusiness/zui/PageHeader';
import Select from '@zenbusiness/zui/Select';
import Stack from '@zenbusiness/zui/Stack';
import Text from '@zenbusiness/zui/Text';
import Tooltip from '@zenbusiness/zui/Tooltip';

import ContactCard from './ContactCard';
import useBusinessEntityContactsQuery from './hooks/useBusinessEntityContactsQuery';
import useContactTypesQuery from './hooks/useContactTypesQuery';
import ContactCreateModal from './modals/ContactCreateModal';
import ContactDeleteModal from './modals/ContactDeleteModal';
import ContactEditModal from './modals/ContactEditModal';
import ContactTypeEditModal from './modals/ContactTypeEditModal';

const ALERT_SX = { marginBottom: 2, width: '100%' };

/*
 * ContactsPage mutations result in the entire contacts list being refetched.
 * This is done because the Contact model is inconsistent which prevents easy caching.
 */
function ContactsPage() {
  const { businessEntityUuid } = useParams();
  const {
    loading: loadingContacts,
    contacts,
    refetch: refetchContacts
  } = useBusinessEntityContactsQuery({ businessEntityUuid });
  const { loading: loadingContactTypes, contactTypes } = useContactTypesQuery();

  const [targetContact, setTargetContact] = useState(null);
  const [actionAlert, setActionAlert] = useState(null);

  const [contactCreateModalOpen, setContactCreateModalOpen] = useState(false);
  const [contactEditModalOpen, setContactEditModalOpen] = useState(false);
  const [contactDeleteModalOpen, setContactDeleteModalOpen] = useState(false);
  const [contactTypeEditModalOpen, setContactTypeEditModalOpen] = useState(false);

  const handleContactCreateModalOpen = () => {
    setTargetContact(null);
    setContactCreateModalOpen(true);
  };
  const handleContactCreateModalClose = () => {
    setTargetContact(null);
    setContactCreateModalOpen(false);
  };

  const handleContactEditModalOpen = (contact) => {
    setTargetContact(contact);
    setContactEditModalOpen(true);
  };
  const handleContactEditModalClose = () => {
    setTargetContact(null);
    setContactEditModalOpen(false);
  };

  const handleContactDeleteModalOpen = (contact) => {
    setTargetContact(contact);
    setContactDeleteModalOpen(true);
  };
  const handleContactDeleteModalClose = () => {
    setTargetContact(null);
    setContactDeleteModalOpen(false);
  };

  const handleContactTypeEditModalOpen = (contact) => {
    setTargetContact(contact);
    setContactTypeEditModalOpen(true);
  };
  const handleContactTypeEditModalClose = () => {
    setTargetContact(null);
    setContactTypeEditModalOpen(false);
  };

  const [filteredContactType, setFilteredContactType] = useState('');

  const handleContactTypeFilterChange = (event) => {
    setFilteredContactType(event.target.value);
  };

  // TODO: Add copy to clipboard functionality?

  const handleCreateContact = () => {
    refetchContacts({
      onSuccess: () => {
        handleContactCreateModalClose();
        setActionAlert('Contact created successfully.');
      }
    });
  };
  const handleDeleteContact = () => {
    refetchContacts({
      onSuccess: () => {
        handleContactDeleteModalClose();
        setActionAlert('Contact deleted successfully.');
      }
    });
  };
  const handleUpdateContact = () => {
    refetchContacts({
      onSuccess: () => {
        handleContactEditModalClose();
        setActionAlert('Contact updated successfully.');
      }
    });
  };
  const handleContactTypeUpdate = () => {
    refetchContacts({
      onSuccess: () => {
        handleContactTypeEditModalClose();
        setActionAlert('Contact roles updated successfully.');
      }
    });
  };

  const contactTypesFoundInContacts = contacts.reduce((acc, cur) => {
    cur.contactTypes.forEach((contactType) => {
      if (!acc.includes(contactType)) {
        acc.push(contactType);
      }
    });
    return acc;
  }, []);

  const filterableContactTypes = contactTypes
    .filter((contactType) => contactTypesFoundInContacts.includes(contactType.name));

  const filteredContacts = filteredContactType === ''
    ? contacts
    : contacts.filter((contact) => (
      contact.contactTypes?.some((contactType) => contactType === filteredContactType)
    ));

  if (loadingContacts || loadingContactTypes) {
    return (
      <PageContainer>
        <Center>
          <LoadingSpinner />
        </Center>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <ContactCreateModal
        contactTypes={contactTypes}
        open={contactCreateModalOpen}
        handleClose={handleContactCreateModalClose}
        handleSave={handleCreateContact}
      />
      {targetContact && (
        <>
          <ContactEditModal
            contact={targetContact}
            open={contactEditModalOpen}
            handleClose={handleContactEditModalClose}
            handleSave={handleUpdateContact}
          />
          <ContactDeleteModal
            contact={targetContact}
            open={contactDeleteModalOpen}
            handleClose={handleContactDeleteModalClose}
            handleSave={handleDeleteContact}
          />
          <ContactTypeEditModal
            contact={targetContact}
            contactTypes={contactTypes}
            open={contactTypeEditModalOpen}
            handleClose={handleContactTypeEditModalClose}
            handleSave={handleContactTypeUpdate}
          />
        </>
      )}
      <Breadcrumbs sx={{ minHeight: '1rem' }}>
        <Link href="/">Home</Link>
        <Link href="/admin/business-entity">Business Entities</Link>
        <Link href={`/business-entity/${businessEntityUuid}`}>Business Entity</Link>
      </Breadcrumbs>
      <PageHeader title="Contacts Manager" />
      {actionAlert && (
        <Alert variant="action" title={actionAlert} sx={ALERT_SX} />
      )}
      <Stack direction="row" justifyContent="space-between" mb={4}>
        <FormControl sx={{ minWidth: 300 }}>
          <InputLabel id="filtered-contact-type-select-label">Filter by Contact Type</InputLabel>
          <Select
            labelId="filtered-contact-type-select-label"
            id="filtered-contact-type-select"
            value={filteredContactType}
            label="Contact Type"
            onChange={handleContactTypeFilterChange}
          >
            <MenuItem value="">All</MenuItem>
            {filterableContactTypes.map((contactType) => (
              <MenuItem
                key={contactType.name}
                value={contactType.name}
                selected={contactType.name === filteredContactType}
              >
                {contactType.displayName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Tooltip title="Create contact">
          <Button size="md" color="secondary" icon={<PersonAddIcon />} onClick={handleContactCreateModalOpen} />
        </Tooltip>
      </Stack>
      <Stack spacing={{ xs: 1, sm: 2 }} direction="row" useFlexGap flexWrap="wrap">
        {filteredContacts.map((contact) => (
          <ContactCard
            key={contact.contactUuid}
            businessEntityUuid={businessEntityUuid}
            contact={contact}
            contactTypes={contactTypes}
            handleEditContact={handleContactEditModalOpen}
            handleDeleteContact={handleContactDeleteModalOpen}
            handleEditContactRoles={handleContactTypeEditModalOpen}
          />
        ))}
        {filteredContacts.length === 0 && (
          <Text>No contacts found.</Text>
        )}
      </Stack>
    </PageContainer>
  );
}

ContactsPage.propTypes = {};

export default ContactsPage;
