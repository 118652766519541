import { useState } from 'react';

import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import Alert from '@zenbusiness/zui/Alert';
import BasicDialog from '@zenbusiness/zui/BasicDialog';
import Center from '@zenbusiness/zui/Center';
import Checkbox from '@zenbusiness/zui/Checkbox';
import FormControl from '@zenbusiness/zui/FormControl';
import FormControlLabel from '@zenbusiness/zui/FormControlLabel';
import FormGroup from '@zenbusiness/zui/FormGroup';
import LoadingSpinner from '@zenbusiness/zui/LoadingSpinner';

import useContactTypeAddMutation from '../hooks/useContactTypeAddMutation';
import useContactTypeRemoveMutation from '../hooks/useContactTypeRemoveMutation';
import useContactTypesQuery from '../hooks/useContactTypesQuery';

function ContactTypeEditModal({
  contact,
  open,
  handleClose,
  handleSave
}) {
  const { businessEntityUuid } = useParams();
  const { loading: loadingContactTypes, contactTypes } = useContactTypesQuery();
  const [removeContactType, { errors: contactTypeRemoveErrors }] = useContactTypeRemoveMutation();
  const [addContactType, { errors: contactTypeAddErrors }] = useContactTypeAddMutation();
  const [mutableContactTypes, setMutatableContactTypes] = useState(contact.contactTypes);

  const errors = [].concat(contactTypeRemoveErrors, contactTypeAddErrors);

  const handleChange = (event) => {
    const { name } = event.target;
    if (!mutableContactTypes.includes(name)) {
      setMutatableContactTypes([...mutableContactTypes, name]);
    } else {
      setMutatableContactTypes(mutableContactTypes.filter((type) => type !== name));
    }
  };

  const handleContactTypeSave = () => {
    const additions = mutableContactTypes
      .filter((ct) => (!contact.contactTypes.includes(ct)))
      .map((addition) => (
        addContactType({
          variables: {
            input: {
              businessEntityUuid,
              contactUuid: contact.contactUuid,
              contactType: addition
            }
          }
        })
      ));

    const deletions = contact.contactTypes
      .filter((ct) => (!mutableContactTypes.includes(ct)))
      .map((deletion) => (
        removeContactType({
          variables: {
            input: {
              businessEntityUuid,
              contactUuid: contact.contactUuid,
              contactType: deletion
            }
          }
        })
      ));

    Promise.all([...additions, ...deletions])
      .finally(() => {
        handleSave();
      });
  };

  if (loadingContactTypes) {
    return (
      <BasicDialog
        title="Edit Contact Roles"
        subtitle="Modify roles for this contact"
        primaryAction={{
          label: 'Save',
          disabled: true
        }}
        open={open}
        onClose={handleClose}
        maxWidth="md"
      >
        <Center>
          <LoadingSpinner />
        </Center>
      </BasicDialog>
    );
  }

  return (
    <BasicDialog
      title="Edit Contact Roles"
      subtitle="Modify roles for this contact"
      primaryAction={{
        label: 'Save',
        onClick: handleContactTypeSave
      }}
      open={open}
      onClose={handleClose}
      maxWidth="md"
    >
      {errors && errors.length > 0 && (
        <Alert variant="alert" title="An unexpected error has occurred, please try adjusting your input and saving again." sx={{ marginBottom: 2, width: '100%' }} />
      )}
      <FormControl sx={{ mx: 3 }} component="fieldset" variant="standard">
        <FormGroup row>
          {contactTypes.map((contactType) => (
            <FormControlLabel
              key={contactType.name}
              sx={{ width: '16rem' }}
              control={(
                <Checkbox
                  checked={mutableContactTypes.includes(contactType.name)}
                  onChange={handleChange}
                  name={contactType.name}
                />
              )}
              label={contactType.displayName}
            />
          ))}
        </FormGroup>
      </FormControl>
    </BasicDialog>
  );
}

ContactTypeEditModal.propTypes = {
  contact: PropTypes.shape({
    contactUuid: PropTypes.string.isRequired,
    contactTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    firstName: PropTypes.string,
    middleName: PropTypes.string,
    lastName: PropTypes.string,
    companyName: PropTypes.string,
    careOf: PropTypes.string,
    suffix: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    country: PropTypes.string,
    county: PropTypes.string
  }).isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired
};

export default ContactTypeEditModal;
