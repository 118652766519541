import { Outlet } from 'react-router-dom';

import Container from '@zenbusiness/zui/Container';

import DevBanner from './DevBanner';
import TopNav from './TopNav';

export default function Template({ children }) {
  return (
    <>
      <DevBanner />
      <TopNav />
      <Container>
        {children || <Outlet />}
      </Container>
    </>
  );
}
