import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LabelIcon from '@mui/icons-material/Label';
import PropTypes from 'prop-types';

import { useIdentity } from '@zenbusiness/application-commons-identity';
import Box from '@zenbusiness/zui/Box';
import Button from '@zenbusiness/zui/Button';
import ButtonGroup from '@zenbusiness/zui/ButtonGroup';
import Card from '@zenbusiness/zui/Card';
import Chip from '@zenbusiness/zui/Chip';
import Divider from '@zenbusiness/zui/Divider';
import Grid from '@zenbusiness/zui/Grid';
import Heading from '@zenbusiness/zui/Heading';
import Stack from '@zenbusiness/zui/Stack';
import Text from '@zenbusiness/zui/Text';
import Tooltip from '@zenbusiness/zui/Tooltip';

import {
  generateContactAddressDisplay,
  generateContactFullNameDisplay
} from './utils';

const FIELD_INPUT_SX = { wordBreak: 'break-word' };

function ContactCard(props) {
  const {
    contact,
    contactTypes,
    handleEditContact,
    handleDeleteContact,
    handleEditContactRoles
  } = props;

  const {
    permissions
  } = useIdentity();

  const editContact = () => {
    handleEditContact(contact);
  };

  const deleteContact = () => {
    handleDeleteContact(contact);
  };

  const editContactRoles = () => {
    handleEditContactRoles(contact);
  };

  const fullNameDisplay = generateContactFullNameDisplay(contact);
  const addressDisplay = generateContactAddressDisplay(contact);

  /*
   * sop_contact is a special contact_type
   * there must be one and only one sop_contact
   */
  const isSop = contact.contactTypes.includes('sop_contact');

  const isRA = contact.contactTypes.includes('registered_agent');

  const canDeleteContact = !isSop && !isRA;

  const isSystemContact = contact.scope === 'system';

  const hasPermissionsToEditSystemContact = (!isSystemContact || (isSystemContact && permissions.has('write:system-contacts')));

  return (
    <Card sx={{ width: { xs: '100%', md: '49%' } }}>
      <Box sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item sx={{ flexGrow: 1 }}>
                <Heading component="h2" size="xl" weight="regular" sx={FIELD_INPUT_SX}>
                  {fullNameDisplay}
                </Heading>
                <Text color="tertiary" size="xs">
                  <code>{contact.contactUuid}</code>
                </Text>
              </Grid>
              <Grid item>
                <ButtonGroup variant="contained" aria-label="Contact Actions">
                  <Tooltip title={hasPermissionsToEditSystemContact ? 'Edit contact' : 'Contact requires elevated permissions to edit'}>
                    <Box>
                      <Button size="sm" color="secondary" icon={<EditIcon />} onClick={editContact} disabled={!hasPermissionsToEditSystemContact} />
                    </Box>
                  </Tooltip>
                  <Tooltip title={!canDeleteContact ? 'Delete contact disabled due to role' : 'Delete contact'}>
                    <Box>
                      <Button size="sm" color="secondary" icon={<DeleteIcon />} onClick={deleteContact} disabled={!canDeleteContact} />
                    </Box>
                  </Tooltip>
                  <Tooltip title="Assign roles to contact">
                    <Button size="sm" color="secondary" icon={<LabelIcon />} onClick={editContactRoles} />
                  </Tooltip>
                </ButtonGroup>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box marginBottom={2}>
              <Text weight="medium">Email</Text>
              <Text sx={FIELD_INPUT_SX}>
                {contact.email || <em>Not on record</em>}
              </Text>
            </Box>
            <Box>
              <Text weight="medium">Phone</Text>
              <Text sx={FIELD_INPUT_SX}>
                {contact.phone || <em>Not on record</em>}
              </Text>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Text weight="medium">Address</Text>
            <Text sx={FIELD_INPUT_SX}>
              {addressDisplay.length > 0 ? addressDisplay.map((part) => (
                <Box key={part}>
                  <Box component="span">{part}</Box>
                </Box>
              )) : <Text sx={{ fontStyle: 'italic' }}>Not on record</Text>}
            </Text>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        <Stack horizontal>
          <Text weight="medium" flexGrow="1">
            Roles
          </Text>
        </Stack>
        <Stack horizontal spacing={1} marginTop={1} flexWrap="wrap">
          {contact.contactTypes.sort().map((contactType) => (
            <Chip
              key={contactType}
              variant="outlined"
              color="primary"
            >
              {contactTypes.find((obj) => (obj.name === contactType))?.displayName}
            </Chip>
          ))}
        </Stack>
      </Box>
    </Card>
  );
}

ContactCard.propTypes = {
  contact: PropTypes.shape({
    contactUuid: PropTypes.string.isRequired,
    contactTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    firstName: PropTypes.string,
    middleName: PropTypes.string,
    lastName: PropTypes.string,
    companyName: PropTypes.string,
    careOf: PropTypes.string,
    suffix: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    country: PropTypes.string,
    county: PropTypes.string,
    scope: PropTypes.string
  }).isRequired,
  contactTypes: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired
  })),
  handleEditContact: PropTypes.func.isRequired,
  handleDeleteContact: PropTypes.func.isRequired,
  handleEditContactRoles: PropTypes.func.isRequired
};

export default ContactCard;
