import { useAuthentication } from '@zenbusiness/application-commons-authentication';
import { gql, useQuery } from '@zenbusiness/application-commons-graphql';

export const QUERY = gql`
  query ContactTypes {
    contactTypes {
      name
      displayName
    }
  }
`;

export default function useContactTypesQuery() {
  const { authenticated } = useAuthentication();
  const {
    loading,
    data: {
      contactTypes = []
    } = {}
  } = useQuery(QUERY, {
    skip: !authenticated,
    variables: {}
  });

  return { loading, contactTypes };
}
