import { useAuthentication } from '@zenbusiness/application-commons-authentication';
import { gql, useQuery } from '@zenbusiness/application-commons-graphql';

export const QUERY = gql`
  query StatesQuery {
    states {
      value
      label
    }
  }
`;

export default function useStatesQuery() {
  const { authenticated } = useAuthentication();
  const {
    loading,
    data: {
      states = []
    } = {}
  } = useQuery(QUERY, {
    skip: !authenticated
  });

  return { loading, states };
}
