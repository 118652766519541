import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import { EnvProvider } from '@zenbusiness/application-commons-env';
import PageError from '@zenbusiness/zui/PageError';

import Authenticated from '#client/App/Authenticated';
import PostRouting from '#client/App/PostRouting';
import { DEFAULT_SEGMENT } from '#client/build-env';
import Template from '#client/components/Template';
import * as ROUTES from '#client/constants/routes';
import * as Pages from '#client/pages';

import Federated from './Federated';

// Upgrades out react-router features to v7, stopping annoying react-router log spam
const reactRouterOpts = {
  future: {
    v7_fetcherPersist: true,
    v7_normalizeFormMethod: true,
    v7_partialHydration: true,
    v7_relativeSplatPath: true,
    v7_skipActionErrorRevalidation: true,
    v7_startTransition: true
  }
};

const router = createBrowserRouter([
  {
    /**
     * Routes which do not require authentication at page load
     */
    element: <PostRouting />,
    children: [
      { path: ROUTES.login, element: <Pages.Login /> },
      { path: ROUTES.logout, element: <Pages.Logout /> },
      { path: ROUTES.callback, element: <Pages.Callback /> }
    ]
  },
  {
    /**
     * Routes which can support authentication but do not require it
     */
    element: (
      <Template>
        <Authenticated optional>
          <PostRouting />
        </Authenticated>
      </Template>
    ),
    children: [
      { index: true, element: <Pages.Landing /> }
    ]
  },
  {
    /**
     * Routes which can support authentication but do not require it
     */
    path: DEFAULT_SEGMENT,
    element: (
      <Template>
        <Authenticated optional>
          <PostRouting />
        </Authenticated>
      </Template>
    ),
    children: [
      { index: true, element: <Pages.Landing /> }
    ]
  },
  {
    /**
     * Routes which require a business entity
     */
    path: `${DEFAULT_SEGMENT}/business-entity`,
    element: (
      <Authenticated>
        <Template>
          <PostRouting />
        </Template>
      </Authenticated>
    ),
    children: [
      { index: true, element: <Pages.BusinessEntities /> }
    ]
  },
  {
    /**
     * Routes which require a business entity
     */
    path: `${DEFAULT_SEGMENT}/business-entity/:businessEntityUuid`,
    element: (
      <Template>
        <Authenticated>
          <PostRouting />
        </Authenticated>
      </Template>
    ),
    children: [
      { path: 'contacts', element: <Pages.Contacts /> },
      { index: true, element: <Pages.Landing /> }
    ]
  },
  {
    /*
      Federated Module Test Routes
      If your MFE has federated entry points, you can recreate those ingress paths here
      in order to test those federated components.
    */
    path: `/${DEFAULT_SEGMENT}/${ROUTES.landing}/*`,
    element: (
      <Authenticated optional>
        <PostRouting />
      </Authenticated>
    ),
    children: [
      {
        // Example for testing a federated named export
        path: 'component',
        element: <Federated exportName="BusinessEntity" />
      },
      {
        // Example for testing a federated default export, with its own routing
        path: '*',
        element: <Federated />
      }
    ]
  },
  {
    path: '*',
    element: (
      <Template>
        <Authenticated optional>
          <PageError>The requested route could not be found.</PageError>
        </Authenticated>
      </Template>
    )
  }
], reactRouterOpts);

export function DirectRoutes() {
  return (
    // ROOT_SEGMENT is used to identify where the router is mounted at.
    // Since this is a direct entry router, the root is the MFE's DEFAULT_SEGMENT
    <EnvProvider env={{ ROOT_SEGMENT: DEFAULT_SEGMENT }}>
      <RouterProvider router={router} />
    </EnvProvider>
  );
}
