import { useState } from 'react';

import LabelIcon from '@mui/icons-material/Label';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import Alert from '@zenbusiness/zui/Alert';
import BasicDialog from '@zenbusiness/zui/BasicDialog';
import Center from '@zenbusiness/zui/Center';
import FormControl from '@zenbusiness/zui/FormControl';
import Grid from '@zenbusiness/zui/Grid';
import InputLabel from '@zenbusiness/zui/InputLabel';
import LoadingSpinner from '@zenbusiness/zui/LoadingSpinner';
import MenuItem from '@zenbusiness/zui/MenuItem';
import Select from '@zenbusiness/zui/Select';

import StructuredForm from '#client/components/StructuredForm';

import { generateContactFormDefinition } from './definitions';
import useContactCreateMutation from '../hooks/useContactCreateMutation';
import useContactTypesQuery from '../hooks/useContactTypesQuery';
// import useCountiesQuery from './hooks/useCountiesQuery';
import useCountriesQuery from '../hooks/useCountriesQuery';
import useStatesQuery from '../hooks/useStatesQuery';
import { mapToFieldErrors } from '../utils';

// TODO: Make Counties a dropdown

function ContactCreateModal({
  open, handleClose, handleSave
}) {
  const { businessEntityUuid } = useParams();
  const { loading: loadingContactTypes, contactTypes } = useContactTypesQuery();
  const { loading: loadingCountries, countries } = useCountriesQuery();
  const { loading: loadingStates, states } = useStatesQuery();
  const [createContact, { errors }] = useContactCreateMutation();

  const [mutableContact, setMutatableContact] = useState({});
  const [mutableContactType, setMutatableContactType] = useState('');

  const handleRoleChange = (event) => {
    const { value } = event.target;
    setMutatableContactType(value);
  };

  const handleContactChange = (event) => {
    const { name, value } = event.target;
    setMutatableContact({
      ...mutableContact,
      [name]: value
    });
  };

  const handleContactSave = () => {
    createContact({
      variables: {
        input: {
          businessEntityUuid,
          contact: mutableContact,
          contactType: mutableContactType
        }
      },
      onSuccess: () => {
        handleSave();
      }
    });
  };

  if (loadingContactTypes || loadingCountries || loadingStates) {
    return (
      <BasicDialog
        title="Create Contact"
        subtitle="Create a new Contact and assign to a Role"
        primaryAction={{
          label: 'Save',
          disabled: true
        }}
        open={open}
        onClose={handleClose}
        maxWidth="md"
      >
        <Center>
          <LoadingSpinner />
        </Center>
      </BasicDialog>
    );
  }

  const contactFormDefinition = generateContactFormDefinition({ countries, states });
  const fieldBoundErrors = mapToFieldErrors(errors);

  const requirementsMet = mutableContactType
    && (
      (mutableContact.firstName && mutableContact.lastName)
      || mutableContact.companyName
    );

  return (
    <BasicDialog
      title="Create Contact"
      subtitle="Create a new Contact and assign to a Role"
      primaryAction={{
        label: 'Save',
        onClick: handleContactSave,
        disabled: !requirementsMet
      }}
      open={open}
      onClose={handleClose}
      maxWidth="md"
    >
      {errors && errors.length > 0 && (
        <Alert variant="alert" title="An unexpected error has occurred, please try adjusting your input and saving again." sx={{ marginBottom: 2, width: '100%' }} />
      )}
      <Grid container direction="row" spacing={2} mb={2}>
        <Grid item xs={1} justifyContent="center" alignItems="center" sx={{ textAlign: 'center' }}>
          <LabelIcon xs={{ width: 24 }} />
        </Grid>
        <Grid item xs={11}>
          <FormControl component="fieldset" fullWidth required>
            <InputLabel id="add-contact-type-label">Add a role</InputLabel>
            <Select
              labelId="add-contact-type-label"
              id="add-contact-type"
              label="Add Role"
              onChange={handleRoleChange}
              value={mutableContactType}
            >
              {contactTypes.map((contactType) => (
                <MenuItem
                  key={contactType.name}
                  value={contactType.name}
                >
                  {contactType.displayName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <StructuredForm
        definition={contactFormDefinition}
        model={mutableContact}
        fieldErrors={fieldBoundErrors}
        handleChange={handleContactChange}
      />
    </BasicDialog>
  );
}

ContactCreateModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired
};

export default ContactCreateModal;
