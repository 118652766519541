import PropTypes from 'prop-types';

import FormControl from '@zenbusiness/zui/FormControl';
import FormHelperText from '@zenbusiness/zui/FormHelperText';
import Grid from '@zenbusiness/zui/Grid';
import InputLabel from '@zenbusiness/zui/InputLabel';
import MenuItem from '@zenbusiness/zui/MenuItem';
import Select from '@zenbusiness/zui/Select';
import TextField from '@zenbusiness/zui/TextField';

function StructuredForm({
  definition,
  handleChange,
  fieldErrors,
  model
}) {
  return definition.map((group) => (
    <Grid key={group.label} container direction="row" spacing={2} mb={2}>
      <Grid item xs={1} justifyContent="center" alignItems="center" sx={{ textAlign: 'center' }}>
        <group.Icon xs={{ width: 24 }} />
      </Grid>
      <Grid item xs={11}>
        <Grid container direction="row" spacing={1}>
          {group.fields.map((field) => (
            <Grid
              item
              key={field.key}
              xs={field?.breakpoints?.xs || 12}
              sm={field?.breakpoints?.sm}
              md={field?.breakpoints?.md}
            >
              {field?.type === 'select' ? (
                <FormControl component="fieldset" fullWidth required>
                  <InputLabel id={`${field}-label`}>{field.label}</InputLabel>
                  <Select
                    labelId={`${field}-label`}
                    id={field.key}
                    error={fieldErrors[field.key]}
                    key={field.key}
                    label={field.label}
                    name={field.key}
                    value={model[field.key] ?? field.defaultValue ?? ''}
                    onChange={handleChange}
                    sx={field.sx}
                    required={field.required}
                    fullWidth
                  >
                    {field?.items?.map((item) => (
                      <MenuItem
                        key={item.key}
                        value={item.value}
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {fieldErrors[field.key] && (
                    <FormHelperText>{fieldErrors[field.key]}</FormHelperText>
                  )}
                </FormControl>
              ) : (
                <TextField
                  error={fieldErrors[field.key]}
                  helperText={fieldErrors[field.key]}
                  key={field.key}
                  label={field.label}
                  name={field.key}
                  value={model[field.key] ?? field.defaultValue ?? ''}
                  onChange={handleChange}
                  sx={field.sx}
                  required={field.required}
                  fullWidth
                />
              )}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  ));
}

StructuredForm.propTypes = {
  definition: PropTypes.arrayOf(PropTypes.shape({})),
  model: PropTypes.shape({}),
  handleChange: PropTypes.func.isRequired,
  fieldErrors: PropTypes.shape({})
};

export default StructuredForm;
