import { useAuthentication } from '@zenbusiness/application-commons-authentication';
import { gql, useQuery } from '@zenbusiness/application-commons-graphql';

export const QUERY = gql`
  query BusinessEntitiesQuery($page: BusinessEntitiesPage, $filter: BusinessEntitiesFilter, $sort: BusinessEntitiesSort) {
    businessEntities(page: $page, sort: $sort, filter: $filter) {
      pageInfo {
        pageSize
        cursor
        hasNext
        hasPrevious
        nextCursor
        previousCursor
        totalNodes
      }
      edges {
        node {
          uuid
          name
          status
          formationStatus
          domesticJurisdiction
          type
          sfId
        }
      }
    }
  }
`;

export default function useBusinessEntitiesQuery(options) {
  const {
    page,
    filter,
    sort
  } = options;

  const { authenticated } = useAuthentication();

  const {
    loading,
    data = {}
  } = useQuery(QUERY, {
    skip: !authenticated,
    variables: {
      page,
      sort,
      filter
    }
  });

  const {
    businessEntities: {
      pageInfo = {},
      edges = []
    } = {}
  } = data;

  const businessEntities = edges.map((edge) => edge.node);

  return {
    loading,
    pageInfo,
    businessEntities
  };
}
