import { useAuthentication } from '@zenbusiness/application-commons-authentication';
import { gql, useQuery } from '@zenbusiness/application-commons-graphql';

export const QUERY = gql`
  query BusinessEntityContacts($businessEntityUuid: UUID!) {
    businessEntity(businessEntityUuid: $businessEntityUuid){
      contacts {
        contactTypes
        contactUuid
        firstName
        middleName
        lastName
        companyName
        careOf
        address1
        address2
        addressIsGoogle
        city
        country
        county
        email
        # TODO: These are modeled wrong in the GQLCommons
        # lat
        # lng
        phone
        state
        suffix
        zip
        scope
      }
    }
  }
`;

export default function useBusinessEntityContactsQuery({ businessEntityUuid }) {
  if (!businessEntityUuid) {
    throw new Error('Unable to retrieve BusinessEntityContact data without businessEntityUuid');
  }
  const { authenticated } = useAuthentication();
  const {
    loading,
    data: {
      businessEntity: {
        contacts = []
      } = {}
    } = {},
    refetch
  } = useQuery(QUERY, {
    skip: !authenticated,
    variables: {
      businessEntityUuid
    }
  });

  return { loading, contacts, refetch };
}
