import { useAuthentication } from '@zenbusiness/application-commons-authentication';
import { gql, useMutation } from '@zenbusiness/application-commons-graphql';

export const MUTATION = gql`
mutation addContactType($input: ContactTypeMutationInput!) {
  addContactType(input: $input) {
    contactUuid
  }
}
`;

export default function useContactTypeAddMutation() {
  const { authenticated } = useAuthentication();
  const [addContactType, { loading, data, errors }] = useMutation(MUTATION, {
    skip: !authenticated,
    noThrow: true
  });

  return [addContactType, { loading, data, errors }];
}
