import { useAuthentication } from '@zenbusiness/application-commons-authentication';
import { gql, useQuery } from '@zenbusiness/application-commons-graphql';

export const QUERY = gql`
  query CountriesQuery {
    countries {
      value
      label
    }
  }
`;

export default function useCountriesQuery() {
  const { authenticated } = useAuthentication();
  const {
    loading,
    data: {
      countries = []
    } = {}
  } = useQuery(QUERY, {
    skip: !authenticated
  });

  return { loading, countries };
}
