import { useState } from 'react';

import PropTypes from 'prop-types';

import Alert from '@zenbusiness/zui/Alert';
import BasicDialog from '@zenbusiness/zui/BasicDialog';
import Center from '@zenbusiness/zui/Center';
import LoadingSpinner from '@zenbusiness/zui/LoadingSpinner';

import StructuredForm from '#client/components/StructuredForm';

import { generateContactFormDefinition } from './definitions';
import useContactUpdateMutation from '../hooks/useContactUpdateMutation';
// import useCountiesQuery from './hooks/useCountiesQuery';
import useCountriesQuery from '../hooks/useCountriesQuery';
import useStatesQuery from '../hooks/useStatesQuery';
import { mapToFieldErrors } from '../utils';

// TODO: Make Counties a dropdown

function ContactEditModal({
  contact, open, handleClose, handleSave
}) {
  const { loading: loadingCountries, countries } = useCountriesQuery();
  const { loading: loadingStates, states } = useStatesQuery();
  const [updateContact, { errors }] = useContactUpdateMutation();
  const [contactChanges, setContactChanges] = useState({});

  // Convert nulls to empty strings for controlled inputs
  const contactWithNoNulls = Object.entries(contact)
    .reduce((acc, [key, value]) => ({
      ...acc,
      [key]: value === null ? '' : value
    }), {});
  const [mutableContact, setMutatableContact] = useState(contactWithNoNulls);

  const handleContactChange = (event) => {
    const { name, value } = event.target;
    setMutatableContact({
      ...mutableContact,
      [name]: value
    });

    const incomingContactChanges = {
      ...contactChanges,
      [name]: value
    };

    if (value === contact[name]) {
      // Remove any changes that result in same as the original value
      delete incomingContactChanges[name];
      setContactChanges(incomingContactChanges);
    } else {
      setContactChanges(incomingContactChanges);
    }
  };

  const handleContactSave = () => {
    updateContact({
      variables: {
        input: {
          contactUuid: contact.contactUuid,
          contact: contactChanges
        }
      },
      onSuccess: () => {
        handleSave();
      }
    });
  };

  if (loadingCountries || loadingStates) {
    return (
      <BasicDialog
        title="Edit Contact"
        subtitle="Modify contact information"
        primaryAction={{
          label: 'Save',
          disabled: true
        }}
        open={open}
        onClose={handleClose}
        maxWidth="md"
      >
        <Center>
          <LoadingSpinner />
        </Center>
      </BasicDialog>
    );
  }

  const contactFormDefinition = generateContactFormDefinition({ countries, states });
  const fieldBoundErrors = mapToFieldErrors(errors);

  return (
    <BasicDialog
      title="Edit Contact"
      subtitle="Modify contact information"
      primaryAction={{
        label: 'Save',
        onClick: handleContactSave
      }}
      open={open}
      onClose={handleClose}
      maxWidth="md"
    >
      {errors && errors.length > 0 && (
        <Alert variant="alert" title="An unexpected error has occurred, please try adjusting your input and saving again." sx={{ marginBottom: 2, width: '100%' }} />
      )}
      <StructuredForm
        definition={contactFormDefinition}
        model={mutableContact}
        fieldErrors={fieldBoundErrors}
        handleChange={handleContactChange}
      />
    </BasicDialog>
  );
}

ContactEditModal.propTypes = {
  contact: PropTypes.shape({
    contactUuid: PropTypes.string.isRequired,
    contactTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    firstName: PropTypes.string,
    middleName: PropTypes.string,
    lastName: PropTypes.string,
    companyName: PropTypes.string,
    careOf: PropTypes.string,
    suffix: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    country: PropTypes.string,
    county: PropTypes.string
  }).isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired
};

export default ContactEditModal;
