import { useAuthentication } from '@zenbusiness/application-commons-authentication';
import { gql, useMutation } from '@zenbusiness/application-commons-graphql';

export const MUTATION = gql`
mutation createContact($input: ContactCreateInput!) {
  addContact(input: $input) {
    contactUuid
  }
}
`;

export default function useContactCreateMutation() {
  const { authenticated } = useAuthentication();
  const [createContact, { loading, data, errors }] = useMutation(MUTATION, {
    skip: !authenticated,
    noThrow: true
  });

  return [createContact, { loading, data, errors }];
}
