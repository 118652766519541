export function generateContactFullNameDisplay(contact) {
  if (contact.companyName) {
    if (contact.careOf) {
      return `${contact.companyName} (care of ${contact.careOf})`;
    }
    return contact.companyName;
  }
  return [contact.firstName, contact.middleName, contact.lastName, contact.suffix]
    .filter((part) => (!!part))
    .map((part) => part.trim())
    .join(' ');
}

export function generateContactAddressDisplay(contact) {
  const cityStateZipParts = [
    contact.city,
    contact.state,
    contact.zip
  ].filter((part) => (!!part));
  const cityStateZip = cityStateZipParts.join(', ');

  const addressParts = [
    contact.address1,
    contact.address2,
    cityStateZip,
    contact.county,
    contact.country
  ].filter((part) => (!!part));

  const addressDisplay = addressParts;

  return addressDisplay;
}

/**
 * @typedef {object} GraphQLError
 * @property {object} extensions
 * @property {object} extensions.errorCode
 * @property {object} extensions.statusCode
 * @property {object} extensions.validationErrors
 * @property {object[]} extensions.validationErrors.field
 * @property {string[]} locations
 * @property {string} message
 * @property {string[]} path
 */

/**
 * @typedef {object} FieldErrors
 * @property {string[]} field
 */

/**
 * Convert GraphQL errors to an schema more friendly to display
 * @param {GraphQLError[]} errors
 * @returns {object}
 */
export function mapToFieldErrors(errors) {
  return errors?.reduce((acc, cur) => {
    const { extensions: { validationErrors = {} } = {} } = cur;
    // TODO: Consider moving this into a server utility (used by resolvers)
    Object.entries(validationErrors).forEach(([field, fieldErrors]) => {
      acc[field] = fieldErrors.map((fieldError) => fieldError.error);
    });
    return acc;
  }, {});
}
