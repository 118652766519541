import { lazy as reactLazy } from 'react';

function lazy(fn, name) {
  const l = reactLazy(() => fn());
  l.displayName = name ? `LazyLoaded<${name}>` : 'LazyLoaded';
  return l;
}

export { default as Landing } from './Landing';
export const Login = lazy(() => import(/* webpackChunkName: 'login' */'./Login'), 'Login');
export const Logout = lazy(() => import(/* webpackChunkName: 'login' */'./Logout'), 'Logout');
export const Callback = lazy(() => import(/* webpackChunkName: 'login' */'./Callback'), 'Callback');

// ADD YOUR NEW PAGES BELOW THIS LINE

export const Dashboard = lazy(
  () => import(/* webpackChunkName: 'dashboard' */'./Dashboard'),
  'Dashboard'
);

export const Contacts = lazy(
  () => import(/* webpackChunkName: 'contacts' */'./Contacts'),
  'Contacts'
);

export const BusinessEntities = lazy(
  () => import(/* webpackChunkName: 'businessEntity' */'./BusinessEntities'),
  'BusinessEntities'
);
