import { useState } from 'react';

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import LinkIcon from '@mui/icons-material/Link';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useSearchParams } from 'react-router-dom';

import useEnv from '@zenbusiness/application-commons-env';
import Breadcrumbs from '@zenbusiness/zui/Breadcrumbs';
import Button from '@zenbusiness/zui/Button';
import FormControl from '@zenbusiness/zui/FormControl';
import IconButton from '@zenbusiness/zui/IconButton';
import InputLabel from '@zenbusiness/zui/InputLabel';
import Link from '@zenbusiness/zui/Link';
import MenuItem from '@zenbusiness/zui/MenuItem';
import PageContainer from '@zenbusiness/zui/PageContainer';
import PageHeader from '@zenbusiness/zui/PageHeader';
import Paper from '@zenbusiness/zui/Paper';
import Select from '@zenbusiness/zui/Select';
import SkeletonText from '@zenbusiness/zui/SkeletonText';
import Stack from '@zenbusiness/zui/Stack';
import Table from '@zenbusiness/zui/Table';
import TableBody from '@zenbusiness/zui/TableBody';
import TableCell from '@zenbusiness/zui/TableCell';
import TableContainer from '@zenbusiness/zui/TableContainer';
import TableHead from '@zenbusiness/zui/TableHead';
import TableRow from '@zenbusiness/zui/TableRow';
import Text from '@zenbusiness/zui/Text';
import TextField from '@zenbusiness/zui/TextField';

import useBusinessEntitiesQuery from './hooks/useBusinessEntitiesQuery';

/*
 * ContactsPage mutations result in the entire contacts list being refetched.
 * This is done because the Contact model is inconsistent which prevents easy caching.
 */
function BusinessEntitiesPage() {
  const { SALESFORCE_BASEURL } = useEnv();
  const [searchParams] = useSearchParams();
  const initAccountUuid = searchParams.get('accountUuid') ?? null;
  const initBusinessEntityName = searchParams.get('businessEntityName') ?? null;
  const initJurisdictionFilingNumber = searchParams.get('jurisdictionFilingNumber') ?? null;

  const initFilters = {
    ...(initAccountUuid && { accountUuid: initAccountUuid }),
    ...(initBusinessEntityName && { businessEntityName: initBusinessEntityName }),
    ...(initJurisdictionFilingNumber && { jurisdictionFilingNumber: initJurisdictionFilingNumber })
  };

  const [cursor, setCursor] = useState(null);
  const [pageSize, setPageSize] = useState(5);
  const [mutatingFilters, setMutatingFilters] = useState(initFilters);
  const [filters, setFilters] = useState(initFilters);

  const {
    loading,
    pageInfo,
    businessEntities = []
  } = useBusinessEntitiesQuery({
    page: {
      ...(cursor && { cursor }),
      pageSize
    },
    filter: filters,
    sort: {
      field: 'updatedAt',
      direction: 'desc'
    }
  });
  const skeletonBusinessEntities = Array(pageSize).fill({});

  const handleNext = () => {
    setCursor(pageInfo.nextCursor);
  };
  const handlePrevious = () => {
    setCursor(pageInfo.prevCursor);
  };
  const handlePageSizeChange = (event) => {
    setCursor(null); // Reset cursors when filters are updated
    setPageSize(Number(event.target.value));
  };
  const handleMutatableFilterChange = (event) => {
    setMutatingFilters({
      ...mutatingFilters,
      [event.target.name]: event.target.value
    });
  };

  const updateFilters = () => {
    setCursor(null); // Reset cursors when filters are updated
    setFilters(mutatingFilters);
  };

  const handleFilterEnter = (event) => {
    if (event.keyCode === 13) {
      updateFilters();
    }
  };
  const handleFilterClick = () => {
    updateFilters();
  };

  return (
    <PageContainer>
      <Breadcrumbs sx={{ minHeight: '1rem' }}>
        <Link to="/">Home</Link>
      </Breadcrumbs>
      <PageHeader title="Business Entities" />
      <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="flex-end" alignItems="center" spacing={2} mb={4}>
        <Text weight="medium">Filters:</Text>
        <TextField
          label="Account UUID"
          name="accountUuid"
          value={mutatingFilters?.accountUuid ?? ''}
          onChange={handleMutatableFilterChange}
          onKeyDown={handleFilterEnter}
          fullWidth
        />
        <TextField
          label="Business Entity Name"
          name="businessEntityName"
          value={mutatingFilters?.businessEntityName ?? ''}
          onChange={handleMutatableFilterChange}
          onKeyDown={handleFilterEnter}
          fullWidth
        />
        <TextField
          label="Jurisdiction Filing Number"
          name="jurisdictionFilingNumber"
          value={mutatingFilters?.jurisdictionFilingNumber ?? ''}
          onChange={handleMutatableFilterChange}
          onKeyDown={handleFilterEnter}
          fullWidth
        />
        <Button color="secondary" onClick={handleFilterClick} fullWidth>
          Filter
          <FilterAltIcon />
        </Button>
      </Stack>
      <TableContainer component={Paper} sx={{ border: '1px solid #ddd' }}>
        <Table sx={{ minWidth: 650 }} aria-label="business entities table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">Domestic Jurisdiction</TableCell>
              <TableCell align="right">Formation Type</TableCell>
              <TableCell align="right">Active</TableCell>
              <TableCell align="right">Salesforce</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && skeletonBusinessEntities.map((_, index) => (
              <TableRow
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row"><SkeletonText /></TableCell>
                <TableCell align="right"><SkeletonText /></TableCell>
                <TableCell align="right"><SkeletonText /></TableCell>
                <TableCell align="right"><SkeletonText /></TableCell>
                <TableCell align="right"><SkeletonText /></TableCell>
              </TableRow>
            ))}
            {businessEntities.map((businessEntity) => (
              <TableRow
                key={businessEntity.uuid}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <Link href={`/business-entity/${businessEntity.uuid}`}>{businessEntity.name}</Link>
                </TableCell>
                <TableCell align="right">{businessEntity.domesticJurisdiction}</TableCell>
                <TableCell align="right">{businessEntity.type}</TableCell>
                <TableCell align="right">{businessEntity.status}</TableCell>
                <TableCell align="right">
                  <IconButton href={`${SALESFORCE_BASEURL}/lightning/r/${businessEntity.sfId}/view`} target="_blank" rel="noreferrer noopener" size="small" disabled={!businessEntity.sfId}>
                    <LinkIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" spacing={2} mt={2}>
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
          <FormControl sx={{ minWidth: 150 }}>
            <InputLabel id="pagesize-label">Results per page</InputLabel>
            <Select
              labelId="pagesize-label"
              id="pagesize"
              value={pageSize}
              label="Page Size"
              onChange={handlePageSizeChange}
              onKeyDown={handleFilterEnter}
            >
              <MenuItem value="5">5</MenuItem>
              <MenuItem value="10">10</MenuItem>
              <MenuItem value="25">25</MenuItem>
            </Select>
          </FormControl>
          <Stack direction="row" ml={2} sx={{ minWidth: 175 }}>
            <Text>
              Results found:&nbsp;
            </Text>
            {pageInfo.totalNodes ? (
              <Text>
                {pageInfo.totalNodes}
              </Text>
            ) : (
              <SkeletonText width={75} />
            )}
          </Stack>
        </Stack>
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
          <Button color="secondary" onClick={handlePrevious} disabled={!pageInfo.hasPrevious} fullWidth>
            <NavigateBeforeIcon />
            Previous
          </Button>
          <Button color="secondary" onClick={handleNext} disabled={!pageInfo.hasNext} fullWidth>
            Next
            <NavigateNextIcon />
          </Button>
        </Stack>
      </Stack>
    </PageContainer>
  );
}

BusinessEntitiesPage.propTypes = {};

export default BusinessEntitiesPage;
