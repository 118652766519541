import BusinessIcon from '@mui/icons-material/Business';
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import PlaceIcon from '@mui/icons-material/Place';
import CareOfIcon from '@mui/icons-material/VolunteerActivism';

export function generateContactFormDefinition(options) {
  const { states, countries } = options;
  return [
    {
      key: 'personGroup',
      label: 'Person',
      Icon: PersonIcon,
      fields: [
        {
          key: 'firstName',
          label: 'First Name',
          required: false,
          sx: {}
        },
        {
          key: 'middleName',
          label: 'Middle Name',
          required: false,
          sx: {}
        },
        {
          key: 'lastName',
          label: 'Last Name',
          required: false,
          sx: {}
        },
        {
          key: 'suffix',
          label: 'Suffix',
          required: false,
          sx: {}
        }
      ]
    },
    {
      key: 'businessGroup',
      label: 'Business',
      Icon: BusinessIcon,
      fields: [
        {
          key: 'companyName',
          label: 'Company Name',
          required: false,
          sx: {}
        }
      ]
    },
    {
      key: 'careOfGroup',
      label: 'Care Of',
      Icon: CareOfIcon,
      fields: [
        {
          key: 'careOf',
          label: 'Care Of',
          required: false,
          sx: {}
        }
      ]
    },
    {
      key: 'emailGroup',
      label: 'Email',
      Icon: EmailIcon,
      fields: [
        {
          key: 'email',
          label: 'Email Address',
          required: false,
          sx: {}
        }
      ]
    },
    {
      key: 'phoneGroup',
      label: 'Phone',
      Icon: PhoneIcon,
      fields: [
        {
          key: 'phone',
          label: 'Phone Number',
          required: false,
          sx: {}
        }
      ]
    },
    {
      key: 'addressGroup',
      label: 'Address',
      Icon: PlaceIcon,
      fields: [
        {
          key: 'address1',
          label: 'Address Line 1',
          required: false,
          sx: {}
        },
        {
          key: 'address2',
          label: 'Address Line 2',
          required: false,
          sx: {}
        },
        {
          key: 'city',
          label: 'City',
          required: false,
          sx: {}
        },
        {
          key: 'state',
          type: 'select',
          label: 'State',
          required: false,
          sx: {
            marginRight: {
              xs: 0,
              sm: 2
            }
          },
          breakpoints: {
            xs: 12,
            sm: 6
          },
          items: states.map((state) => ({
            key: state.value,
            value: state.value,
            label: state.label
          }))
        },
        {
          key: 'zip',
          label: 'Zip Code',
          required: false,
          sx: {},
          breakpoints: {
            xs: 12,
            sm: 6
          }
        },
        {
          key: 'county',
          label: 'County',
          required: false,
          sx: {}
        },
        {
          key: 'country',
          type: 'select',
          label: 'Country',
          required: false,
          sx: {},
          items: countries.map((country) => ({
            key: country.value,
            value: country.value,
            label: country.label
          }))
        }
      ]
    }
  ];
}
