import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import Alert from '@zenbusiness/zui/Alert';
import BasicDialog from '@zenbusiness/zui/BasicDialog';
import Text from '@zenbusiness/zui/Text';

import useContactRemoveMutation from '../hooks/useContactRemoveMutation';

function ContactDeleteModal({
  contact,
  open,
  handleClose,
  handleSave
}) {
  const { businessEntityUuid } = useParams();
  const [removeContact, { errors }] = useContactRemoveMutation();

  const handleContactDelete = () => (
    removeContact({
      variables: {
        input: {
          businessEntityUuid,
          contactUuid: contact.contactUuid
        }
      },
      onSuccess: () => {
        handleSave();
      }
    })
  );

  return (
    <BasicDialog
      title="Remove Contact"
      subtitle="Remove this contact from the business entity."
      primaryAction={{
        label: 'Confirm',
        onClick: handleContactDelete
      }}
      open={open}
      onClose={handleClose}
      maxWidth="md"
    >
      {errors && errors.length > 0 && (
        <Alert variant="alert" title="An unexpected error has occurred, please try again." sx={{ marginBottom: 2, width: '100%' }} />
      )}
      <Text>Delete this contact?</Text>
    </BasicDialog>
  );
}

ContactDeleteModal.propTypes = {
  contact: PropTypes.shape({
    contactUuid: PropTypes.string.isRequired
  }).isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired
};

export default ContactDeleteModal;
