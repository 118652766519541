import { useAuthentication } from '@zenbusiness/application-commons-authentication';
import { gql, useMutation } from '@zenbusiness/application-commons-graphql';

export const MUTATION = gql`
mutation removeContactType($input: ContactTypeMutationInput!) {
  removeContactType(input: $input) {
    contactUuid
  }
}
`;

export default function useContactTypeRemoveMutation() {
  const { authenticated } = useAuthentication();
  const [removeContactType, { loading, data, errors }] = useMutation(MUTATION, {
    skip: !authenticated,
    noThrow: true
  });

  return [removeContactType, { loading, data, errors }];
}
