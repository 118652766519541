import { useAuthentication } from '@zenbusiness/application-commons-authentication';
import { gql, useMutation } from '@zenbusiness/application-commons-graphql';

export const MUTATION = gql`
mutation removeContact($input: ContactRemoveInput!) {
  removeContact(input: $input) {
    contactUuid
  }
}
`;

export default function useContactRemoveMutation() {
  const { authenticated } = useAuthentication();
  const [removeContact, { loading, data, errors }] = useMutation(MUTATION, {
    skip: !authenticated,
    noThrow: true
  });

  return [removeContact, { loading, data, errors }];
}
